import Vue from 'vue'
import SvgIcon from '../components/svg-icon.tmpl.vue'// svg组件

// register globally
Vue.component('svg-icon', SvgIcon)

const requireAll = requireContext => requireContext.keys().map(requireContext)
const req = require.context('./svg', false, /\.svg$/)
console.log(requireAll(req), 'reqqqqqqqqqqqqqq')
requireAll(req)
