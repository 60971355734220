'use strict'
const apiProvider = {}

apiProvider.apiConfig = {}

// [ext] if NODE_ENV is production , load the static site-map.js
apiProvider.load = function () {
  if (process.env.NODE_ENV === 'production') {
    apiProvider.apiConfig['CESHI_API_HOST'] =window.API.CESHI_API_HOST// [ext] reference from /static/site-map.js
  } else {
     apiProvider.apiConfig['CESHI_API_HOST'] = 'http://dev-hapi-mirror.76iw.com'
    //apiProvider.apiConfig['CESHI_API_HOST'] = '/http://yapi.proxy.oneweone.cn/mock/1145'
  } 
}
apiProvider.load()
apiProvider.mapModuleRoute = function (module = 'CESHI_API_HOST') {
  if (module) {
    if (module === 'CESHI_API_HOST') {
      return (process.env.NODE_ENV === 'production' ? (location.protocol + '//') : '') + apiProvider.apiConfig['CESHI_API_HOST']
    }
    // if (module === 'CESHI_API_HOSTaa') {
    //   return (process.env.NODE_ENV === 'production' ? ('') : '') + apiProvider.apiConfig['CESHI_API_HOST']
    // }
  }
}

apiProvider.getApiAddress = function (url, module) {
  let apiAddress = ''
  if (url) {
    if (url.indexOf('/') === 0) {
      apiAddress = this.mapModuleRoute(module) + url
    } else {
      apiAddress = this.mapModuleRoute(module) + '/' + url
    }
  }
  return apiAddress
}

export default apiProvider
