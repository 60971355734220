import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const appRouter = new VueRouter({
    mode: 'history',
    saveScrollPosition: true,
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
       
       
        {
            path: '/pay',
            name: 'pay',
            component: resolve => require(['../../components/pay/pay.vue'], resolve)
        },
        {
            path: '/getResults',
            name: 'getResults',
            component: resolve => require(['../../components/getResults/getResults.vue'], resolve)
        },
        {
            path: '/home',
            name: 'home',
            component: resolve => require(['../../components/home/home.vue'], resolve)
        },
        {
            path: '/shopping',
            name: 'shopping',
            component: resolve => require(['../../components/shopping/shopping.vue'], resolve)
        },
        // {
        //     path: '/resetNetwork',
        //     name: 'resetNetwork',
        //     component: resolve => require(['../../components/resetNetwork/resetNetwork.vue'], resolve)
        // },
        
        
    ]
})
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)

}
// appRouter.beforeEach((to, from, next) => {
//     console.log(store.state.loginStor.token,'store.state.loginStor.tokenstore.state.loginStor.tokenstore.state.loginStor.token')
//     if (!store.state.loginStor.token) {
//         console.log(to.matched,'to.matched.lengthto.matched.lengthto.matched.length')
//        // router.push('/login')
//         if (
//             to.matched.length > 0 &&
//             !to.matched.some(record => record.meta.auth)
//         ) {
//             next()
//         } else {
//             if (to.path !== '/login') {
//                 next({ path: '/login' })
//             } else {
//                 next()
//             }
//             // next({ path: '/login' })
//         }
//     } else {
//         next()
//     }
//     document.title = to.meta.title
// })
  export default appRouter