
<template>
  <div id="app">
    <!-- <transition name="page-fade" mode="out-in"> -->
      <router-view></router-view>
    <!-- </transition> -->
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {

    }
  },
  created() {
     window.addEventListener('resize', function () {
      if(document.activeElement.tagName === 'input'){
        document.activeElement.scrollIntoView({behavior: "smooth"})
        }
    })
  }
}
</script>

<style>
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
  height: 100%;
}
.el-button {
  height: 32px;
  padding:  0 10px;
  font-size: 12px;
}
.el-button  span {
  margin: 0;
}
ul li {
  list-style: none;
}
i {
  font-style: normal;
}
</style>
