import Vue from 'vue'
import App from './App.vue'
import http from './common/plugin/http'
import api from './common/services/api.provider'
import router from './common/router/index'
import store from './common/services/store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import iView from 'iview'
import entTable from './common/components/tab/tab.vue'
import 'iview/dist/styles/iview.css'
import '@/common/icons'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Fragment from 'vue-fragment'
import 'lib-flexible/flexible'
import Vant from 'vant';
import 'vant/lib/index.css';
import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)
Vue.use(Fragment.Plugin)
Vue.use(VueQuillEditor)
Vue.use(Vant);

Vue.use(iView)
Vue.use(ElementUI);
Vue.component('entTable', entTable)
Vue.prototype.$http = http
Vue.prototype.$api = api
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')


