<template>
  <div class="ext-table">
    <el-table
      border
      ref="export-table"
      :data="table.data"
      :max-height="maxHeight"
      style="width: 100%"
       size="small"
      :sortable="false"
      @selection-change="handleSelectionChange"
     
      @sort-change="handleSortChange"
    >
      <el-table-column
        v-if="table.selection"
         :selectable="selectEnable"
        type="selection"
        width="42"
      ></el-table-column>
      <template v-for="(item, index) in table.column">
        <el-table-column
          align="center"
          v-if="item.type === 'img'"
          :key="index"
          :prop="item.prop"
          :sortable="item.sortable ? item.sortable : false"
          :min-width="item.minWidth"
          :label="item.label"
        >

          <template slot-scope="scope"><img :src="scope.row[item.prop]" alt=""></template>
        </el-table-column> 
        <el-table-column
          align="center"
          v-if="item.type === 'normal'"
          :key="index"
          :prop="item.prop"
          :sortable="item.sortable ? item.sortable : false"
          :sort="item.sort ? item.sort : false"
          :min-width="item.minWidth"
          :label="item.label"
        >
          <template slot-scope="scope">{{
            scope.row[item.prop]
          }}</template>
        </el-table-column>
        <el-table-column
         align="center"
          v-else-if="item.type === 'handle'"
          :key="index"
          :min-width="item.minWidth"
          :label="item.label"
        >
          <template slot-scope="scope">
            <template  >
              <el-button
                class="slot-span"
                v-for="val in item.handleList"
                :key="val.type"
                type="text"
                
                @click="editData(val.type, scope.row, val.prop)"
              >
              
                <span v-if="val.type == 'status'">{{
                  val.text[scope.row[val.prop]]
                }}</span> 
                
                <span v-else-if="val.type == 'recommend'">{{
                  val.text[scope.row[val.prop]]
                }}</span> 
                <span v-else-if="val.type == 'isReturn'">{{
                  val.text[scope.row[val.prop]]
                }}</span>
                <span v-else-if="val.type == 'status_base'">{{
                  [scope.row[val.prop]] == 0 ? '审核' : [scope.row[val.prop]] != 2 ? val.text[scope.row[val.base_prop]] : ''
                }}
                </span> 
                <span v-else>{{ val.label }}</span>
              </el-button>
              
            </template>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          v-if="item.type === 'status' && item.prop === 'shelf'"
          :key="index"
          :prop="item.prop"
          :sortable="item.sortable ? item.sortable : false"
          :min-width="item.minWidth"
          :label="item.label"
        >    
          <template slot-scope="scope">
              <slot name="shelf" :row="scope.row"></slot>
            </template>
        </el-table-column>
        <el-table-column
          align="center"
          v-if="item.type === 'status' && item.prop === 'recommend'"
          :key="index"
          :prop="item.prop"
          :sortable="item.sortable ? item.sortable : false"
          :min-width="item.minWidth"
          :label="item.label"
        >    
          <template slot-scope="scope">
              <slot name="recommend" :row="scope.row"></slot>
            </template>
        </el-table-column>
        <el-table-column
          align="center"
          v-if="item.type === 'status' && item.prop === 'releaseStatus'"
          :key="index"
          :prop="item.prop"
          :sortable="item.sortable ? item.sortable : false"
          :min-width="item.minWidth"
          :label="item.label"
        >    
          <template slot-scope="scope">
              <slot name="shelf" :row="scope.row"></slot>
            </template>
        </el-table-column>
        <el-table-column
          align="center"
          v-if="item.type === 'custom'"
          :min-width="isCheck ? item.minWidth :20"
          :label="item.label"
          :key="index"
        >
          <template slot-scope="scope">
            <slot name="oper" :row="scope.row"></slot>
          </template>
        </el-table-column>
      </template>
    </el-table>
    
      <slot name="slot_tab_btn" style="width: 300px"></slot>
    <el-pagination
    
      v-if="!table.isHidePagintion && table.data.length != 0"
     
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      layout="total,sizes, prev, pager, next, jumper"
      :total="table.total"
      background
    ></el-pagination>
    
  </div>
  
</template>

<script>
export default {
  name: 'extTable',
  props: {
    table: {
      type: Object,
      default: () => {
        return {
          total: 0, //总页数
          page: 1, //当前页数
          pageSize: 10, //每页条数
          data: [], //表格数据
          column: [], //表格相关信息
          isHidePagintion: false, //是否隐藏分页
          selection: false, //是否多选
          maxHeight: null, //最大高度
        }
      },
      required: true,
    },
    maxHeight: {
      type: String,
      default: null,
    },
    isCheck: {
      type: Boolean,
      default: null,
    }
  },
  data() {
    return {
      // page: 1,
      // pageSize: 10,
    }
  },
  mounted() {
    console.log(this.isCheck,'CCCCCCCCCCCCCCCKKKKKKKKK')
  },
  methods: {
    editData(flag, scope, prop) {
      this.$emit('editClick', { flag, scope, prop })
    },
    handleSelectionChange(val) {
      this.$emit('selectionChange', val)
    },
    selectEnable(val) {
      if(val.shelf == 2) {
        return false;
      } else {
        return true;
      }
    },
    handleSortChange(column, prop, order ) {
      this.$emit('sortChange', column, prop, order)
    },
    handleSizeChange(val) {
      this.$emit('tablePages', {
        pageSize: val,
      })
    },
    handleCurrentChange(val) {
      this.$emit('tablePages', {
        page: val,
      })
    },
  },
  watch: {
    table: {
      handler: function (val) {
        console.log(val,'valllllllllllllllllllllzzz')
        this.page = val.page
        this.pageSize = val.pageSize
        // this.table.total = val.total / val.pageSize
      },
      deep: true,
    },
  },
}
</script>
<style lang="scss">

.ext-table {
  margin-top: 20px;
  th {
    padding: 0 !important;
    height: 40px;
    line-height: 40px;
  }
  td {
    padding: 0 !important;
    height: 40px;
    line-height: 40px;
  }
  .el-table .cell img {
    width: 50px;
    height: 50px;
    margin-top:7px;
  }
  // .el-table__header-wrapper {
  //   height: 40px;
  //   .el-table__header {
  //     height: 100% !important;
  //   }
  // }
 // position: relative;;
  // .el-table--small {
  //   font-size: 13px;
  //   color: rgba(0, 0, 0, 0.85);
  //   th {
  //     padding: 15px 0;
  //     .cell {
  //       font-size: 14px;
  //       color: rgba(0, 0, 0, 0.45);
  //     }
  //   }
  // }
}
.el-pagination {
 // position: absolute;
 // right: 0;
  // margin-left: calc(100% - 440px);
  margin-top: 20px;
  float: right;
}
</style>
<style lang="scss" scoped>
// .ext-table {
//   .slot-span {
//     margin-right: 8px;
//     color: #38ca7b;
//     cursor: pointer;
//   }
//   .activation {
//     height: 6px;
//     width: 6px;
//     position: relative;
//     top: -1px;
//     margin-right: 6px;
//     background-color: rgb(64, 210, 131);
//     border-radius: 50%;
//   }
//   .activation_no {
//     background-color: red;
//   }
//   .activation_grey {
//     background-color: #bfbfbf;
//   }
//   .ent-pagination {
//     text-align: center;
//     padding: 20px 0px 10px;
//   }
//   .grey {
//     color: #999;
//   }
//   .ellipsis-txt {
//     color: #38ca7b;
//   }
// }
</style>
