'use strict'

const leftMenuStore = {}

/* @usage : store state data shcema
 this.$store.state.token  */
 leftMenuStore.state = {
    isCollapse: true 
}

/* @usage : unit operation
 this.$store.commit('USER_TOKEN') */
 leftMenuStore.mutations = {
  'CHANGE_MENU_CILLAPSE_ACTION_IS_SHOW' (state) {
    state.isCollapse = !state.isCollapse 
  }, 
}

/* @usage : based on state ,  return state's length or filter state data */
leftMenuStore.getters = {

}

/* @usage : basic the operation for view component , this.$store.dispatch('USER_TOKEN_ACTION') */
leftMenuStore.actions = {
  'CHANGE_MENU_CILLAPSE_ACTION' ({ commit }) {
    commit('CHANGE_MENU_CILLAPSE_ACTION_IS_SHOW')
  },
}

export default leftMenuStore