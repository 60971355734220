import Axios from 'axios'
import ES6Promise from 'es6-promise'
//import store from '../../common/services/store'
ES6Promise.polyfill() // 解决ie9不支持promise问题
   // Axios = process.env.NODE_ENV === 'development' ? '/api' : 'http://112.125.25.70:8080/lian';
  // 请求拦截，在这里设置请求之前设置一些herder，传参之类的信息
  Axios.interceptors.request.use(
    config => {
      // config.headers['X-Requested-With'] = 'XMLHttpRequest'
      return config
    }
  )
  
  // 响应拦截，接口返回的数据
  Axios.interceptors.response.use(
    res => {
      console.log(res, 'qqqqqqqqqqqqqqqqq')
      return res
    },
    err => {
      console.log(err.response, 'err')
      if (err.response) {
        switch (err.data.code) {
          case 401:
            window.location.href = err.data.message
            break
        }
        return Promise.reject(err.response)
      } else if (err.message && err.message.indexOf('Network Error') > -1) {
        // If the error occurs on request(401), it would be handled and cleaned by Axios,
        // and only Network Error is returned( response is null)
        return Promise.reject(err)
      }
    })
  
  const http = {}
  //get请求
  http.get = function (url, params) {
    let config = {
      method: 'GET',
      url: url,
      params: params, // params : { key : value}
      headers: {
        'Content-Type': 'application/xxxx-form; charset=UTF-8'
      },
      responseType: 'json'
    }
    return Axios(config)
  }
  //post请求
  http.post = function (url, obj) {
    let config = {
      method: 'POST',
      url: url,
      data: JSON.stringify(obj), // change obj to json obj
      headers: {
        'Content-Type': 'application/json; charset=UTF-8'
      },
      responseType: 'json'
    }
    return Axios(config)
  }
  //formData
  http.formData = function (url, obj) {
    var formData = new FormData();
    for(let key in obj) {
        formData.append(key, obj[key]);
    }
    let config = {
      method: 'POST',
      url: url,
      data: JSON.stringify(obj), // change obj to json obj
      //config.headers['App-Client'] = JSON.stringify({"platform": 4});
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'App-Client': JSON.stringify({"platform":4})
      },
      responseType: 'json'
    }
    // if(needToken) {
    //   config.headers.Authorization = ''
    // }

    return Axios(config)
  }
  
  export default http