import Vue from 'vue'
import Vuex from 'vuex'
//import loginStor from '../../components/login/store'
import leftMenuStore from '../components/breadcrumb/breadcrumb.store'

Vue.use(Vuex)
export default new Vuex.Store(
    {
        strict: process.env.NODE_ENV !== 'production', // [ext] open vuex strict mode
        modules: {
           // loginStor: loginStor,
            leftMenuStore: leftMenuStore,
        }
    }
)